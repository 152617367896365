import React from 'react';
import '../css/VideoPlayer.scss';
const VideoPlayer = ({ src, width = "640", height = "360" }) => {
    return (
        <div className='player'>
            <video  width={width} height={height} controls autoPlay>
                <source src={src} type="video/mp4" />
                Votre navigateur ne supporte pas la balise vidéo.
            </video>
        </div>
    );
};

export default VideoPlayer;