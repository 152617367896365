import React, { useState, useEffect } from 'react';
import '../css/Dial.css';

const Dial = ({ percentage }) => {
    const size = 270; // Largeur ajustée pour le SVG
    const circleRadius = 100; // Rayon du cercle inchangé
    const needleLength = 95; // Ajustement pour que l'aiguille soit un peu plus longue
    const center = size / 2; // Centre ajusté pour le nouveau SVG
    const [currentDegree, setCurrentDegree] = useState(0); // Départ à 0 pour le demi-cercle
    const targetDegree = percentage * 180 / 100; // Conversion du pourcentage en degrés pour un demi-cercle

    useEffect(() => {
        const start = performance.now();
        const duration = 1000; // Durée de l'animation

        const animate = (time) => {
            const elapsedTime = time - start;
            const progress = Math.min(elapsedTime / duration, 1); // Progression de 0 à 1
            const degreeIncrement = progress * (targetDegree - currentDegree);
            const newDegree = currentDegree + degreeIncrement;

            setCurrentDegree(newDegree);

            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }, [percentage, targetDegree]);

    const needleX = center + -needleLength * Math.cos(Math.PI * currentDegree / 180);
    const needleY = center - needleLength * Math.sin(Math.PI * currentDegree / 180); // Inversé pour le demi-cercle

    // Ajustement pour éloigner les chiffres du cercle
    const textRadius = circleRadius + 20; // Plus éloigné pour le nouveau SVG
    const marks = Array.from({ length: 6 }).map((_, index) => { // Moins de marques pour un demi-cercle
        const angle = index * 36; // Espacement adapté pour un demi-cercle
        const markX = center + -textRadius * Math.cos(Math.PI * angle / 180);
        const markY = center-5 - textRadius * Math.sin(Math.PI * angle / 180); // Inversé pour le demi-cercle

        return (
            <text key={index} x={markX} y={markY} fill="#ffffff" fontSize="14" textAnchor="middle" alignmentBaseline="middle">
                {index * 20}%
            </text>
        );
    });

    return (<div className='dial-container'>
        <svg className='dialSVG' width={size} height={size / 2}> {/* Ajustement de la hauteur pour un demi-cercle */}
            <defs>
                <linearGradient id="gradient" x1="0%" y1="100%" x2="100%" y2="100%">
                    <stop offset="0%" style={{ stopColor: 'green', stopOpacity: 0.8 }} />
                    <stop offset="50%" style={{ stopColor: 'orange', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: 'red', stopOpacity: 0.8 }} />
                </linearGradient>
            </defs>
            <path d={`M ${center - circleRadius} ${center} A ${circleRadius} ${circleRadius} 0 0 1 ${center + circleRadius} ${center}`} fill="none" stroke="white" strokeWidth="2" fill="url(#gradient)" />
            <line x1={center} y1={center} x2={needleX} y2={needleY} stroke="grey" strokeWidth="2" />
            {marks}
        </svg>
        <div className='dialPercentageText'>{percentage}%</div></div>
    );
};

export default Dial;
