import React, { useEffect, useState } from 'react';
import '../css/Result.scss';
import Dial from "./Dial";

function OlderResults({text, setResultsToDisplay}){

    const [userLang, setUserLang] = useState(navigator.language );
    const translations = {
        en: {
            resultTitle: <span>Analysis of your dependency.</span>,
            btnBack:'Back',
        },
        fr: {
            resultTitle:  <span>Analyse de votre dépendence.</span>,
            btnBack:'Retour',
        },
        es: {
            resultTitle: <span>Análisis de tu dependencia.</span>,
            btnBack:'Atrás',
        }
    };
    const getPercent =(data)=>{     const regex = /\[(.*?)\]\s*([\s\S]*)/;

        const match = data.match(regex);

        if (match) {
           return(match[1].replace('%', '')); // Contiendra 'valeur'


        }

    }
    // Fonction pour convertir les \n en éléments <br />
    const formatTextResult = (text) => {
        return text.split('\n').map((item, index, array) => (
            <React.Fragment key={index}>
                {item}{index < array.length - 1 && <br />}
            </React.Fragment>
        ));
    };
    return <div className="result-component">
        <Dial percentage={getPercent(text)} />
        <div className='result-tilte'>
            <hr/>
            {(translations[userLang.slice(0, 2)] || translations["en"]).resultTitle}
            <hr/>

        </div>

        <div className='textResult-content madimi-one-regular'>
            {formatTextResult(text)}
        </div>
        <button className="button button-primary btn-newTest" onClick={() => {setResultsToDisplay(null)}}  >{(translations[userLang.slice(0, 2)] || translations["en"]).btnBack}</button>
    </div>
}

export default OlderResults;