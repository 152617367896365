import React, { useEffect, useState } from 'react';
import '../css/OldTestDisplayer.scss';
import OldTestThumbail from "./OldTestThumbail";


function OldTestDisplayer({setResultsToDisplay , oldTest, testAutorisation, nextDate, setInTest}){

    const [userLang, setUserLang] = useState(navigator.language );
    const translations = {
        en: {
            oldTestContainerTitleText: <span>Your previous tests.</span>,
            doNewTest: 'Perform a new test',
            unautorizedTest:'Remaining time:',
            delaisAdvice :'Deadlines reduced to 15 days for users who have generated their personalized plan.',
            days:'day(s)'
        },
        fr: {
            oldTestContainerTitleText:  <span>Vos tests précédents.</span>,
            doNewTest: 'Réaliser un nouveau test',
            unautorizedTest:'Temps restant:',
            delaisAdvice :'Délais réduit à 15 jours pour les utilisateurs ayant généré leur plan personnalisé.',
            days:'jour(s)'
        },
        es: {
            oldTestContainerTitleText: <span>Tus pruebas anteriores.</span>,
            doNewTest: 'Realizar una nueva prueba',
            unautorizedTest:'Tiempo restante:',
            delaisAdvice :'Plazos reducidos a 15 días para los usuarios que hayan generado su plan personalizado.',
            days:'día(s)'
        }
    };

    return <div className="result-component">

        <div className='result-tilte'>
            <hr/>
            {(translations[userLang.slice(0, 2)] || translations["en"]).oldTestContainerTitleText}
            <hr className='hrbottomMargin'/>

        </div>

        <div className='oldResult-container madimi-one-regular' >
            {oldTest.map(test => {

                return (<OldTestThumbail key={test.id}  setResultsToDisplay={setResultsToDisplay} testData={test}/>)

            })
            }
        </div>
        {testAutorisation && <button className="button button-primary btn-newTest" onClick={() => setInTest(true)}  >{(translations[userLang.slice(0, 2)] || translations["en"]).doNewTest}</button>}
        {!testAutorisation && <div className='unautorizedTest'>
            <span>{(translations[userLang.slice(0, 2)] || translations["en"]).unautorizedTest} <span className='colorRed'> {nextDate} </span>{(translations[userLang.slice(0, 2)] || translations["en"]).days}</span>
            <span className='delaisTestAdvice'>{(translations[userLang.slice(0, 2)] || translations["en"]).delaisAdvice }</span>
        </div>
        }
    </div>
}

export default OldTestDisplayer;