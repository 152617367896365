import React, { useEffect, useState } from 'react';
import '../css/QuestionControl.scss';


/* global tf_addictionNonce */


function QuestionControl({questionText, setAnswer,setTestFinished,setInTest, setLoading}) {
    const [questionNumberTab,setQuestionNumberTab] = useState(['1','2','3','4','5','6','7','8','9','10','11','12'])
    const [actualQuestion,setActualQuestion] = useState('');
    const [actualIndex,setActualIndex] = useState(0);
    const [answerText,setAnswerText] = useState([])
    const [currentAnswer, setCurrentAnswer] = useState("");
    const [userLang, setUserLang] = useState(navigator.language );
    const translations = {
        en: {
            btnQuit: 'Quit',
            btnValider: 'Valid',
            years:'years'
        },
        fr: {
            btnQuit: 'Quitter',
            btnValider: 'Valider',
            years:'ans'
        },
        es: {
            btnQuit: 'Dejar',
            btnValider: 'Validar',
            years:'años'

        }
    };
    useEffect(() => {
        setActualIndex(1);
        let ind =('Q'+(actualIndex).toString())
        setActualQuestion(questionText[ind])
    }, [questionText]);
    useEffect(() => {
        setActualIndex(actualIndex+1);
        setActualQuestion(questionText['Q'+(actualIndex+1).toString()])

        if(actualIndex===questionText.length-1){
            setAnswerText(answerText)
        }
    }, [answerText]);
    const nextQuestion=(text)=>{
        if (currentAnswer.trim() !== "") {
            // Ajoute la réponse actuelle au tableau des réponses et réinitialise la réponse actuelle
            setAnswerText((prevAnswers) => [...prevAnswers, currentAnswer]);
            setCurrentAnswer(""); // Réinitialise l'input pour la prochaine question
            setAnswer((prevAnswers) => [...prevAnswers, currentAnswer]);
            if(answerText.length===11){

                setTestFinished(true);

                setLoading(false);
            }
        } else {
            // Informe l'utilisateur qu'une réponse est nécessaire
            alert("Veuillez entrer une réponse.");
        }
    }
    return (
        <div className="question-component">
            <div className="question-number "><div className='questionCounterDisplay'> {actualIndex}</div></div>
            <div className="question-content">
                <div className="question-text">{actualQuestion}</div>
                {actualIndex!==1 && actualIndex!==4 && actualIndex!==5 && <input type="text" className="answer-input"  value={currentAnswer}   onChange={(e) => setCurrentAnswer(e.target.value)} placeholder="Votre réponse..."/>}
                {actualIndex===1 && <input aria-label="Time" type="time" className='timepick'  onChange={(e) => setCurrentAnswer(e.target.value)}defaultValue="00:00" />}

                {(actualIndex===4 || actualIndex===5) && <input aria-label="number" type="number" className='timepick' min='0' max='100' step='1' onChange={(e) => setCurrentAnswer(e.target.value)} defaultValue="0" />} {actualIndex===5 &&(translations[userLang.slice(0, 2)] || translations["en"]).years}
                {(actualIndex===9) && <input aria-label="number" type="number" className='timepick' min='1' max='10' step='1' onChange={(e) => setCurrentAnswer(e.target.value)} defaultValue="0" />}
            </div>
            <div className="question-actions">
                <button className="button button-primary" onClick={nextQuestion}>{(translations[userLang.slice(0, 2)] || translations["en"]).btnValider}</button>
                <button className="button button-secondary" onClick={() => setInTest(false)}>{(translations[userLang.slice(0, 2)] || translations["en"]).btnQuit}</button>
            </div>
        </div>
    );
}

export default QuestionControl;