import QuestionControl from './components/QuestionControl';
import './App.scss';
import React, {useEffect, useState} from "react";
import Loader from './components/Loader'
import Dial from './components/Dial'
import Result from './components/Result'
import OldTestDisplayer from "./components/OldTestDisplayer";
import VideoPlayer from "./components/VideoPlayer";
import OlderResults from "./components/OlderResults";
/* global tf_addictionNonce */
function App() {
    const [questionTest,setQuestionTest] = useState([]);
    const [initial,setInitial] = useState(true);
    const [answer,setAnswer] = useState([]);
    const [testFinshed,setTestFinished] = useState(false);
    const [loading,setLoading] = useState(false);
    const [testResult,setTestResult] = useState('');
    const [testPercent,setTestPercent] = useState(0)
    const [loadText,setLoadText] = useState('Loading')
    const [userLang, setUserLang] = useState(navigator.language );
    const [inTest,setInTest] =useState(false)
    const [hasOldTest,setHasOldTest] = useState(false);
    const [hasOldTestVerified,setHasOldTestVerified] = useState(false);
    const [oldTests,setOldTests] = useState([]);
    const [testAutorisation, setTestAutorisation] = useState(false);
    const [daysRemaining,setsDayRemaining] = useState(30);
    const [resultsToDisplay, setResultsToDisplay] = useState(null);




    function formatDate(datetime) {
        const datePart = datetime.split(' ')[0];
        const parts = datePart.split('-');
        // Retourne un objet Date
        console.log('format date:' , new Date(parts[0], parts[1] - 1, parts[2]))
        return new Date(parts[0], parts[1] - 1, parts[2]); // Mois en JS commence à 0
    }
    function isOlderThan30Days(testDate) {
        console.log('tes addiction date' , testDate);
        const currentDate = new Date(); // Date actuelle
        const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 30 jours en millisecondes
        console.log('test Result',(currentDate - testDate) > thirtyDaysInMilliseconds);
        return (currentDate - testDate) > thirtyDaysInMilliseconds;
    }
    function daysSince(date) {
        const now = new Date(); // La date actuelle
        const startDate = formatDate(date); // Transformez votre date de chaîne en objet Date
        const differenceInMilliseconds = now - startDate; // Différence en millisecondes
        const millisecondsPerDay = 1000 * 60 * 60 * 24; // Nombre de millisecondes par jour
        const differenceInDays = Math.floor(differenceInMilliseconds / millisecondsPerDay); // Différence en jours

        return 30-differenceInDays;
    }
    const translations = {
        en: {
            loadResult: <span>We analyze your answers and upload your results.</span>,
            loadOldTest: <span>We retrieve your previous results...</span>,
            urlVideo:'https://m67.tech/wp-content/uploads/2024/04/tutoAddiction_EN.mp4',
        },
        fr: {
            loadResult:  <span>Nous analysons vos réponses et chargeons vos resultats.</span>,
            loadOldTest: <span>Nous récupérons vos résultats précédents...</span>,
            urlVideo:'https://m67.tech/wp-content/uploads/2024/04/tutoAddiction_FR.mp4',
        },
        es: {
            loadResult: <span>Analizamos tus respuestas y subimos tus resultados.</span>,
            loadOldTest: <span>Recuperamos tus resultados anteriores...</span>,
            urlVideo:'https://m67.tech/wp-content/uploads/2024/04/tutoAddiction_ES.mp4',
        }
    };
    const getQuestion=(testType)=>{
        fetch('http://m67.tech/wp-json/tf_addiction/v1/testquestions/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Ajoutez ici d'autres en-têtes si nécessaire, comme un en-tête d'autorisation
            },
            body: JSON.stringify({
                testType: testType,
                testLanguage: navigator.language
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
               setQuestionTest(data[0]); // Traitez ici les données reçues

            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }
    useEffect(() => {
        if(initial){
        getQuestion('addiction');}
        setInitial(false)
    }, [questionTest,testFinshed]);
    useEffect(() => {
        if(!inTest){
        setLoadText((translations[userLang.slice(0, 2)] || translations["en"]).loadOldTest)
        setLoading(true)
        fetch('https://m67.tech/wp-json/tf_addiction/v1/old-addiction-test',
            {
            method: 'GET',
            headers: {
                'X-WP-Nonce': tf_addictionNonce.nonce,
            },

            credentials: 'include'
        })
            .then(response => {

                if (response.status === 200) {
                    // Traitement spécifique pour le statut 200
                    return response.json().then(data => {
                        if (data.data && data.data.status && data.data.status === 800){
                            setHasOldTest(false);
                            setHasOldTestVerified(true);
                            setInTest(true);
                            setTestAutorisation(true);
                            setLoading(false);
                        }else{
                        setOldTests(data);
                        setHasOldTest(true);
                        setHasOldTestVerified(true);
                        let lastTestDate = formatDate(data[data.length-1].results_date);
                        console.log('lastTestDate',lastTestDate)
                            console.log('older 30 days:' ,isOlderThan30Days(lastTestDate));
                        setTestAutorisation(isOlderThan30Days(lastTestDate));
                        setsDayRemaining(daysSince(data[data.length-1].results_date));
                        setLoading(false);}
                    });
                }
            })


    }

    }, [inTest]);
    useEffect(() => {
        if(!initial && testFinshed) {
            setLoadText((translations[userLang.slice(0, 2)] || translations["en"]).loadResult)
            setLoading(true)
            fetch('https://m67.tech/wp-json/test_addiction/v1/submit-addiction-test', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-Nonce': tf_addictionNonce.nonce, // Remplacez 'votreNonceIci' par la valeur actuelle de votre nonce
                    'Accept-Language': navigator.language // Envoie la langue du navigateur
                },
                body: JSON.stringify({
                    answers: answer,
                }),
                credentials: 'include' // Nécessaire pour les cookies d'authentification, si l'API est utilisée sur le même domaine
            })
                .then(response => response.json())
                .then(data => {
                    setTestResult(data);
                    const regex = /\[(.*?)\]\s*([\s\S]*)/;

                    const match = data.match(regex);

                    if (match) {
                        setTestPercent(match[1].replace('%', '')); // Contiendra 'valeur'
                        setTestResult(match[2]); // Contiendra 'reste de mon texte'
                        console.log('match2',match[2])
                    }
                    setLoading(false);
                    setInTest(false)
                })
                .catch((error) => console.error('Error:', error));
        }
    }, [answer]);
    useEffect(() => {
console.log('resulttoDisplay', resultsToDisplay)
    }, [resultsToDisplay]);
  return (
      <div className="App">

          {!hasOldTest && <VideoPlayer className='player' src={(translations[userLang.slice(0, 2)] || translations["en"]).urlVideo}/>}
          {resultsToDisplay !== null &&<OlderResults text={resultsToDisplay} setResultsToDisplay={setResultsToDisplay}/>}
          {hasOldTestVerified && hasOldTest && !inTest && !testFinshed && <OldTestDisplayer setResultsToDisplay={setResultsToDisplay} oldTest={oldTests} testAutorisation={testAutorisation} nextDate={daysRemaining} setInTest={setInTest}/>}
          {!testFinshed && inTest && <QuestionControl questionText={questionTest} setAnswer={setAnswer} setLoading={setLoading} setTestFinished={setTestFinished} setInTest={setInTest}/>}
          {loading && <Loader loadText={loadText}/>}

          {testFinshed && !loading && !inTest && <Result textResult={testResult} testPercent={testPercent} setInTest={setInTest} setTestFinished={setTestFinished}/>}

      </div>
  );
}

export default App;
