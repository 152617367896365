import React, { useEffect, useState } from 'react';
import '../css/Loader.scss';

function Loader({loadText}){

    return <div className='testResltut-container'>
        <div className='LoaderText'>{loadText}</div>
        <div className='LoaderImageContainer'>
            <img className='loaderImage' src='https://m67.tech/wp-content/uploads/2024/02/Logo-tabac-finishing.jpeg'/>
        </div>
    </div>
}
export default Loader;